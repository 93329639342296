.events {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Ensures even distribution of event cards */
}

.events h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    color: #007F73;
}

/* Styling for the event card */
.event-card {
    width: 450px; /* Increased width for a wider card */
    height: 550px; /* Reduced height for a shorter card */
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px; /* Reduced padding for a more compact look */
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure space between content and button */
    text-align: center; /* Center text inside the card */
}

/* Image styling */
.event-card img {
    width: 100%;
    height: 220px; /* Fixed height to keep it short */
    object-fit: cover; /* Ensure the image covers the space proportionally */
    border-radius: 10px;
    margin-bottom: 10px;
}

/* Button styling */
.event-card button {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: center; /* Center the button horizontally */
    margin-top: auto; /* Push the button to the bottom of the card */
}

.event-card button:hover {
    background-color: #45a049;
}
