/* Gallery container */
.gallery {
    padding: 20px;
    background-color: #f5f5f5;
    color: #333;
}

.gallery h1 {
    color: #007F73;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
}

/* Gallery folders layout */
.gallery-folders {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

/* Individual gallery folder */
.gallery-folder-link {
    text-decoration: none;
}

.gallery-folder {
    width: 250px;
    height: 250px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s;
}

.gallery-folder:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Thumbnail image */
.folder-thumbnail {
    width: 100%;
    height: 70%;
    object-fit: cover;
}

/* Folder info section */
.folder-info {
    padding: 10px;
    text-align: center;
}

.folder-info h2 {
    font-size: 1.5rem;
    color: #007F73;
    margin: 0;
}

.folder-info p {
    color: #4CCD99;
    font-size: 1rem;
    margin: 5px 0 0;
}
