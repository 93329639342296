/* src/components/BlogDetail.css */
.blog-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-detail h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.blog-detail-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 20px;
}

.blog-detail p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #333;
}
