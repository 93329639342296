/* src/components/BlogPost.css */
.blog-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    max-width: 300px;
    text-align: center;
    transition: box-shadow 0.3s, transform 0.3s;
    position: relative; /* For thumbnail positioning */
}

.blog-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.blog-card-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 15px;
}

.blog-card h2 {
    font-size: 1.5em;
    margin: 0 0 10px;
}

.blog-card p {
    font-size: 1em;
    color: #555;
    margin: 0 0 20px;
}

.blog-card-link {
    display: inline-block;
    font-size: 1em;
    color: #FFC700;
    text-decoration: none;
    border: 2px solid #FFC700;
    border-radius: 4px;
    padding: 10px 20px;
    background-color: #FFF455;
    transition: background-color 0.3s, color 0.3s;
}

.blog-card-link:hover {
    background-color: #FFC700;
    color: #fff;
}
