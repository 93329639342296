/* Home Container */
.home {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-height: 100vh; /* Ensures the content is centered vertically */
    background: linear-gradient(to right, #007F73, #4CCD99); /* Gradient background */
}

/* Home Content */
.home-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
    position: relative;
    font-family: 'Raleway', sans-serif; /* Applying Raleway font */
}

/* Column Styling */
.column {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    transition: opacity 0.6s ease-in-out;
    font-family: 'Raleway', sans-serif; /* Apply Raleway here too */
}

/* Headers and Paragraphs */
.column h1, .column h2, .column h3 {
    font-family: 'Raleway', sans-serif; /* Apply Raleway */
    margin-bottom: 10px;
    font-weight: 600; /* Bold weight for headers */
}

.column p {
    font-family: 'Raleway', sans-serif; /* Apply Raleway */
    margin-bottom: 15px;
    font-weight: 300; /* Lighter weight for paragraphs */
}

/* Image Styling */
.home-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* Flag Toggle */
.flag-toggle {
    width: 40px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
    transition: transform 0.3s ease-in-out;
}

.flag-toggle:hover {
    transform: scale(1.1); /* Slight scale effect on hover */
}
