/* src/components/Blog.css */
.blog {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
}

.blog h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
