/* src/components/Footer.css */
.footer {
    padding: 20px;
    background-color: #333;
    color: #fff;
    text-align: center;
}

.footer-social a {
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
}
