.home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 20px;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the rows horizontally */
    text-align: center;
    padding: 20px;
}

.row {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to new rows */
    justify-content: center; /* Center the cards horizontally */
    gap: 20px; /* Space between cards */
    margin-bottom: 20px; /* Space below each row */
}

.profile-card {
    background-color: #f9f9f9;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px; /* Fixed width for each card */
    box-sizing: border-box; /* Ensures padding is included in the width */
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
}

h2 {
    font-family: 'Times New Roman', serif;
    font-weight: bold;
}

h3 {
    font-family: 'Arial', sans-serif;
    font-weight: normal;
    color: #555;
}

p {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.5;
}
