/* Contact Section with Gradient Background */
.contact-container {
    background: linear-gradient(to right, #007F73, #4CCD99); /* Gradient background */
    padding: 40px; /* Adds padding around the card */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensures the card is centered vertically */
}

/* Contact Card */
.contact {
    background-color: #FFFFFF; /* White background for the card */
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.contact h1 {
    color: #007F73; /* Primary text color */
    font-size: 2.5em;
    margin-bottom: 10px;
}

.contact p {
    color: #007F73; /* Secondary text color */
    font-size: 1.2em;
    margin-bottom: 20px;
}

.home-image {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
