/* src/components/EventCard.css */
.event-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    background-color: #fff;
    margin: 20px;
    max-width: 300px;
    cursor: pointer;
    height: 400px; /* Fixed height for uniformity */
}

.event-card:hover {
    transform: scale(1.05);
}

.event-card-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.event-card-content {
    padding: 15px;
    flex-grow: 1; /* Allows the content area to fill remaining space */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Aligns content to the top */
}

.event-card-title {
    font-size: 1.5em;
    margin: 0;
    color: #007F73;
}

.event-card-date {
    color: #4CCD99;
    margin: 5px 0;
}

.event-card-number {
    color: #007F73;
    margin: 5px 0;
    font-weight: bold;
}

.event-card-description {
    font-size: 1em;
    color: #333;
    margin: 10px 0; /* Adds space below the description */
}

.event-card-link {
    display: inline-block;
    margin-top: auto; /* Pushes the link to the bottom of the card */
    font-size: 1em;
    color: #FFC700;
    text-decoration: none;
    border: 1px solid #FFC700;
    border-radius: 4px;
    padding: 8px 12px;
    background-color: #FFF455;
    transition: background-color 0.2s, color 0.2s;
}

.event-card-link:hover {
    background-color: #FFC700;
    color: #fff;
}
