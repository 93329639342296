
.event-detail {
    padding: 20px;
    text-align: center;
}

.event-image {
    width: 100%;
    height: auto;
    max-width: 600px;
    border-radius: 8px;
    margin-bottom: 20px;
}
.back-link {
    display: inline-block;
    margin-top: 20px;
    font-size: 1.2em;
    color: #FFC700;
    text-decoration: none;
    border: 2px solid #FFC700;
    border-radius: 4px;
    padding: 10px 20px;
    background-color: #FFF455;
    transition: background-color 0.3s, color 0.3s;
}
.back-link:hover {
    background-color: #007F73;
    border: 2px solid #4CCD99;
    color: #fff;
}
.signup-link {
    display: inline-block;
    margin-top: 20px;
    font-size: 1.2em;
    color: #007F73;
    text-decoration: none;
    border: 2px solid #007F73;
    border-radius: 4px;
    padding: 10px 20px;
    background-color: #4CCD99;
    transition: background-color 0.3s, color 0.3s;
}

.signup-link:hover {
    background-color: #FFC700;
    border: 2px solid #FFF455;
    color: #fff;
}
