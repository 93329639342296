.gallery-detail {
    text-align: center;
    padding: 20px;
}

.gallery-thumbnails {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px; /* Add space between thumbnails */
    padding: 20px;
}

.thumbnail {
    width: 200px; /* Larger size for thumbnails */
    height: 150px; /* Larger height for thumbnails */
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.thumbnail:hover {
    transform: scale(1.1); /* Slightly enlarge thumbnail on hover */
}

.gallery-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Height relative to viewport for better responsiveness */
    margin: 20px 0;
}

.gallery-image {
    max-width: 80%; /* Larger image size in individual view */
    max-height: 80vh; /* Prevent images from exceeding viewport height */
    height: auto;
    transition: max-width 0.3s ease, transform 0.3s ease;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 100;
}

.nav-button:hover {
    background: rgba(0, 0, 0, 0.8);
}

.nav-button:first-of-type {
    left: 10px;
}

.nav-button:last-of-type {
    right: 10px;
}

.back-button {
    display: block;
    margin: 10px auto;
    background-color: #007F73;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.back-button:hover {
    background-color: #005c4f;
}
